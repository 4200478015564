import React from "react"

import Layout from "../components/Layout"
import { AboutText as TermsText, FinePrint } from '../components/StyledText'

const Terms = () => (
    <Layout navColor="#131313" background="#131313">
    <TermsText>
      <FinePrint>
        <h1>Terms of Use</h1>
        <p>Last updated September 29th, 2020</p>
        <br/>
        <p>All sections of these Terms of Use that, by their nature, should survive termination will survive termination, including, without limitation, the sections entitled User Content, Indemnity, Disclaimer of Warranty and Limitation of Liability, General, and Complete Agreement.</p>

        <h1>Intro</h1>
        The ZipTips website and iOS application is an online platform for learning about new iOS Technologies in Swift. It is provided to you by Beachead Technologies, Inc. ("us" or "we" or "our" or Beachead Technologies), in connection with our partners, and developers (Creators).

        <p>So that we may safely and responsibly manage this site for all of our users, your use of this site is subject to certain terms and conditions. The terms and conditions set forth below ("Terms of Use" or “Terms”), as well as the privacy policy set forth at <a href="http://www.ZipTips.io/privacy">www.ZipTips.io/privacy</a>, apply to your use of the services and functionality provided on or through the ZipTips website and iOS application (the "Services").</p>

        <p>By accessing and using the Services, you acknowledge that you have read, understood, and agree to be bound by these Terms of Use and the Privacy Policy. If you do not accept these Terms of Use and the Privacy Policy, you are not authorized to use the Services.</p>

        <p>Beachead Technologies and You acknowledge that these Terms are concluded between Beachead Technologies and You, and not with Apple, and Beachead Technologies, not Apple, is solely responsible for the Licensed Application and the content thereof.</p>

        <h1>Use of Services</h1>
        <p>By agreeing to the Terms, you are granted a non-transferable license to use the Services on any Apple device that you own or control and as permitted by the Usage rules set forth in Apple’s App Store Terms of Service.</p>

        <p>The Services allow for the delivery of software, text, tips, ZipTips, graphics, code, images, video, audio, data and other material (collectively referred to as the "Content"). The Content is owned by us or may be provided through an arrangement we have with our Creators. The Content is protected by copyright under both United States and foreign laws. Unauthorized use of the Content may violate copyright, trademark, and other laws. You have no rights in or to the Content, and you may not use the Content except as permitted under these Terms of Use. No other use is permitted without prior written consent from us or the owner of the Content. You must retain all copyright and other proprietary notices contained in the original Content on any copy you make of the Content. You may not sell, transfer, assign, license, sublicense, or modify the Content or reproduce, display, publicly perform, make a derivative version of, distribute, or otherwise use the Content in any way for any public or commercial purpose, except to utilize features of the Services that, by their nature, involve publishing or sharing of Content with the public. If you violate any part of these Terms of Use, your permission to access and/or use the Content and Services automatically terminates and you must immediately destroy any copies you have made of the Content.</p>

        <p>Various company, product, and service names displayed on the Services may be trademarks or service marks owned by others (the "Third-Party Trademarks"). Your use of the Services should be construed as granting, by implication, estoppel, or otherwise, any license or right to use any Third-Party Trademark displayed on this Services. The Third-Party Trademarks may not be used to disparage any applicable third-party, any of their products or services, or in any manner in which, in our reasonable judgment, may damage any goodwill in the Third-Party Trademarks.
        In the event of any third party claim that the Services or your possession and use of the Services infringes on that third party’s intellectual property rights, we are solely responsible for the investigation, defense, settlement, and discharge of any such intellectual property infringement claim. Under no circumstances will Apple be responsible for the investigation, defense, settlement and discharge of any such intellectual property infringement claim.</p>

        <p>The Services include access to links to, and content and data from, third-party websites ("External Services"). These links, content, and data are provided solely as a convenience to you and not as an endorsement by us of the content on such External Services. The content of such External Services is developed and provided by others. In addition, the Services permit access to content posted, stored, or displayed at the direction of users of the Services, for which we cannot accept any responsibility or liability.</p>

        <p>The Services are for personal use only and may not be used in connection with any commercial endeavors except those that are specifically approved by us. </p>

        <p>The following activities are expressly prohibited: (i) collecting usernames and/or email addresses of other users by electronic or other means for the purpose of sending unsolicited email or other communications, (ii) any use of the Services, which in our sole judgment, degrades the reliability, speed, or operation of the Services or any underlying hardware or software thereof, (iii) use of web scraping, web harvesting, or web data extraction methods from Beachead Technologies even if the Beachead Technologies account owner gives permission; and (iv) any use of the Services which is unlawful or in violation of these Terms of Use.</p>

        <p>By using the Services, we and you acknowledge and agree that Apple, and Apple’s subsidiaries, are third party beneficiaries of the Terms, and that, upon your acceptance of the Terms, Apple will have the right (and will be deemed to have accepted the right) to enforce the Terms against you as a third party beneficiary thereof.</p>

        <p>We are solely responsible for providing any maintenance and support necessary with respect to the Services. We and You acknowledge that Apple has no obligation whatsoever to furnish any maintenance and support services with respect to the application.</p>

        <p>By using the Services, you represent and warrant that you are 13 years of age or older. Your account may be terminated without warning, if we believe that you are under 13 years of age.
        Your use of the Services is subject, in our sole discretion, to termination at any time.</p>
        <h1>User Content</h1>
        <p>The Services may now or in the future include functionality to permit the submission of Content at the direction of users of the Services ("User Content") and the hosting, sharing, and/or publishing of such User Content and content created by our Creators (“Creator Content”). You understand that whether or not such User Content is published, we do not guarantee any confidentiality with respect to any submissions.</p>

        <p>You shall be solely responsible for User Content or Creator Content you submit and the consequences of our posting or publishing such User Content or Creator Content. In connection with any User Content or Creator Content you submit, you affirm, represent, and/or warrant that: (i) you own or have the necessary licenses, rights, consents, and permissions to use and authorize us to use all patent, trademark, trade secret, copyright or other proprietary rights in and to any and all User Content or Creator Content to enable inclusion and use of the User Content or Creator Content in the manner contemplated by the Services and these Terms of Use; and (ii) you have the express consent, release, and/or permission of each and every identifiable individual person in the User Content or Creator Content to use the name or likeness of each and every such identifiable individual person to enable inclusion and use of the User Content or Creator Content in the manner contemplated by the Services and these Terms of Use. </p>

        <p>By submitting the User Content or Creator Content to us, you hereby grant us a perpetual, worldwide, non-exclusive, royalty-free, sublicensable and transferable license to use, reproduce, distribute, prepare derivative works of, modify, display, and perform all or any portion of the User Content or Creator Content in connection with our provision of the Services and our (and our successors') business, including without limitation for promoting and redistributing part or all of the Services (and derivative works thereof) in any media formats and through any media channels. You also hereby grant each user of the Services a non-exclusive license to access your User Content or Creator Content through the Services, if you have permitted such access through your account settings, and to use, reproduce, distribute, prepare derivative works of, display and perform such User Content or Creator Content as permitted through the functionality of the Services and under these Terms of Use. We may maintain copies of any User Content or Creator Content for purposes of backup, security, or maintenance, or as required by law.</p>

        <p>In connection with User Content or Creator Content, you further agree that you will not: (i) submit material that is copyrighted, protected by trade secret or otherwise subject to third party proprietary rights, including privacy and publicity rights, unless you are the owner of such rights or have permission from their rightful owner to post the material and to grant us all of the license rights granted herein; (ii) publish falsehoods or misrepresentations that could damage us or any third party; (iii) submit material that is unlawful, obscene, defamatory, libelous, threatening, pornographic, harassing, hateful, racially or ethnically offensive, or encourages conduct that would be considered a criminal offense, give rise to civil liability, violate any law, or is otherwise inappropriate; (iv) post advertisements or solicitations of business; or (v) impersonate another person. We do not endorse any User Content or Creator Content or any opinion, recommendation, or advice expressed therein, and we expressly disclaim any and all liability in connection with any User Content or Creator Content. </p>

        <p>We do not permit copyright infringing activities and infringement of intellectual property rights in connection with the Services, and we will remove all Content and User Content and Creator Content if properly notified that such Content or User Content or Creator Content infringes on another's intellectual property rights. We may remove any Content and User Content or Creator Content without prior notice. We may also terminate your access to the Services, if you are determined to be a repeat infringer. A repeat infringer is anyone who has been notified of infringing activity more than once and/or has had any User Content removed from the Services more than twice. We also reserve the right to decide whether Content or User Content or or Creator Content is appropriate and complies with these Terms of Use for violations other than copyright infringement and violations of intellectual property law, such as, but not limited to, pornography, obscene or defamatory material, or excessive length. We may remove such User Content or Creator Content and/or terminate your access for uploading such material in violation of these Terms of Use at any time, without prior notice and at its sole discretion.</p>

        <p>If you are a copyright owner or an agent thereof and believe that any User Content or other Content infringes upon your copyrights, you may submit a notification pursuant to the Digital Millennium Copyright Act ("DMCA") by providing our Copyright Agent with the following information in writing (see 17 U.S.C 512(c)(3) for further detail):</p>

        <p>(i) A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed;</p>

        <p>(ii) Identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works at a single online site are covered by a single notification, a representative list of such works at that site;</p>

        <p>(iii) Identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled and information reasonably sufficient to permit the service provider to locate the material;</p>

        <p>(iv) Information reasonably sufficient to permit us to contact you, such as an address, telephone number, and, if available, an electronic mail address;</p>

        <p>(v) A statement that you have a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law; and</p>

        <p>(vi) A statement that the information in the notification is accurate, and under penalty of perjury, that you are authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.</p>

        <p>Our designated Copyright Agent to receive notifications of claimed infringement and other notices relating to User Content or Creator Content and / or violation of these Terms of Use(e.g., violations of criminal laws) is: Geoffrey Brookins, c / o Beachead Technologies, Inc. / Email: terms@beachead.com. </p>

        <p>For clarity, only DMCA notices and notices relating to complaints in connection with User Content or violations of these Terms of Use should go to the Copyright Agent; any other feedback, comments, requests for technical support, and other communications should be addressed to our standard support contacts as identified on the Services.</p>

        <h1>Right to Modify Terms</h1>
        <p>We reserve the right to change these Terms at any time. If the changes are material, we’ll let you know by email or posting a notice on the website before the changes go into effect. The notice will designate a reasonable amount of time (the “Notice Period”) after which the new terms will go into effect for all users. If you don’t agree to the new terms, please delete your account within the Notice Period. If you do not delete your account within the Notice Period, your content and use of the ZipTips website and iOS application will be subject to the new terms going forward.</p>

        <h1>Right to Modify Service</h1>
        <p>We do our best to provide you a reliable and evolving service, but we reserve the right to change, terminate, or restrict access to any aspect of the service, at any time, without notice.</p>

        <h1>Forms and Public Areas</h1>
        <p>Beachead Technologies, its officers, directors, employees, affiliates, agents, attorneys, representatives make no endorsements of any particular product or treatment, makes no representations and assumes no liability relative to any content, opinions, comments, e-mail addresses, and/or computer viruses which may be transmitted in any Public Area.</p>

        <p>If you use a Form or Public Area on the ZipTips.io website or iOS application, you are solely responsible for your own communications, the consequences of posting those communications, and your reliance on any communications found in the Forms and Public Areas.</p>

        <p>In no event will Beachead Technologies, its officers, directors, employees, affiliates, agents, attorneys, representatives, partners, sponsors, advertisers or other affiliated persons or entities be liable to you or anyone else for any decision made or action taken by you or anyone else in reliance upon the information provided through the ZipTips website or iOS application.You will hold Beachead Technologies, its officers, directors, employees, affiliates, agents, attorneys, representatives, partners, sponsors, advertisers or other affiliated persons or entities harmless for any such actions or decisions taken by you in reliance upon such information.</p>

        <h1>Privacy</h1>
        <p>When you use ZipTips website or iOS application, you consent to the collection and use of information as detailed in our Privacy Policy(www.ZipTips.io / privacy).If you’re outside the United States, you consent to the transfer, storage, and processing of your information—including but not limited to the content you posted or transferred to the site or iOS application and any personal information—to and within the United States and other countries.</p>

        <h1>Subscription Fees for ZipTips</h1>
        <p>If you opt to sign up for a paid Beachead Technologies subscription, you will be subject to subscription fees associated with that subscription.You will be required to submit payment in advance for access to the Beachead Technologies subscription content.</p>
        <h1>Payment</h1>

        <p>Payment for the Beachead Technologies subscriptions will be made by a valid credit card accepted through Apple’s App Store. You hereby authorize us to charge your credit card for such amounts associated with any subscriptions you choose to enroll in. Amounts paid for the Beachead Technologies services, including prepayments, are not refundable.</p>

        <p>Apple will have no warranty obligation whatsoever with respect to the Licensed Application, and any other claims, losses, liabilities, damages, costs or expenses attributable to any failure to conform to any warranty will be Our sole responsibility.</p>

        <h1>Indemnity</h1>
        <p>You agree to defend, indemnify, and hold us harmless from and against any claims, actions or demands, including, without limitation, reasonable legal and accounting fees, arising or resulting from your breach of these Terms of Use or your uploading of, access to, or use or misuse of the Content or the Services. We shall provide notice to you of any such claim, suit, or proceeding and shall assist you, at your expense, in defending any such claim, suit or proceeding. We reserve the right to assume the exclusive defense and control of any matter which is subject to indemnification under this section. In such case, you agree to cooperate with any reasonable requests assisting our defense of such matter.</p>

        <h1>Disclaimer of Warranty and Limitation of Liability</h1>
        <p>WE, OUR AFFILIATES, OUR PARTNERS, AND OUR AND THEIR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, SUPPLIERS, OR LICENSORS, MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE CONTENT (INCLUDING THE USER CONTENT), INCLUDING BUT NOT LIMITED TO ITS ACCURACY, RELIABILITY, COMPLETENESS, TIMELINESS, OR RELIABILITY.
        NEITHER WE NOR OUR AFFILIATES OR PARTNERS SHALL BE SUBJECT TO LIABILITY FOR TRUTH, ACCURACY, OR COMPLETENESS OF ANY INFORMATION CONVEYED TO USERS OF THE SERVICES OR FOR ERRORS, MISTAKES OR OMISSIONS THEREIN OR FOR ANY DELAYS OR INTERRUPTIONS OF THE DATA OR INFORMATION STREAM FROM WHATEVER CAUSE. YOU AGREE THAT YOU USE THE SERVICES AND THE CONTENT AT YOUR OWN RISK.</p>
        <p>WE MAKE NO WARRANTY THAT THE SERVICES WILL BE AVAILABLE ERROR FREE OR THAT THE SERVICES OR THE CONTENT ARE FREE OF COMPUTER VIRUSES OR SIMILAR CONTAMINATION OR DESTRUCTIVE FEATURES. IF YOUR USE OF THE SERVICES OR THE CONTENT RESULTS IN THE NEED FOR SERVICING OR REPLACING EQUIPMENT OR DATA, WE SHALL NOT BE RESPONSIBLE FOR THOSE COSTS.
        THE SERVICES AND CONTENT ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS WITHOUT ANY WARRANTIES OF ANY KIND. WE HEREBY DISCLAIM ALL WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE WARRANTY OF TITLE, MERCHANTABILITY, AND NON INFRINGEMENT OF THIRD PARTIES' RIGHTS FOR PARTICULAR PURPOSE.</p>
        <p>IN NO EVENT SHALL WE BE LIABLE FOR ANY DAMAGES (INCLUDING, WITHOUT LIMITATION, INCIDENTAL AND CONSEQUENTIAL DAMAGES, LOST PROFITS, OR DAMAGES RESULTING FROM LOST DATA OR BUSINESS INTERRUPTION) RESULTING FROM THE USE OR INABILITY TO USE THE SERVICES AND THE CONTENT, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), OR ANY OTHER LEGAL THEORY, IN EXCESS OF ONE HUNDRED DOLLARS, EVEN IF A WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
        THE FAILURE OF Beachead Technologies TO EXERCISE OR ENFORCE ANY RIGHT OR PROVISION OF THESE TERMS SHALL NOT CONSTITUTE A WAIVER OF THAT RIGHT OR PROVISION. THE TERMS AND OUR PRIVACY POLICY CONSTITUTE THE ENTIRE AGREEMENT BETWEEN YOU AND Beachead Technologies AND GOVERN YOUR USE OF THE Beachead Technologies SERVICES, SUPERSEDING ANY PRIOR AGREEMENTS BETWEEN YOU AND Beachead Technologies (INCLUDING, BUT NOT LIMITED TO, ANY PRIOR VERSIONS OF THE TERMS). YOU AGREE THAT THESE TERMS AND YOUR USE OF THE Beachead Technologies SERVICES ARE GOVERNED BY THE INTERNAL SUBSTANTIVE LAWS OF THE State of New York, WITHOUT RESPECT TO ITS CONFLICT OF LAW PROVISIONS. BEFORE RESORTING TO COURTS, WE STRONGLY ENCOURAGE YOU TO CONTACT US DIRECTLY TO TRY TO WORK IT OUT.</p>

        <p>Should the Services not conform to any applicable warranty, you may notify Apple, and Apple will refund the purchase price for the Services to you. To the maximum extent permitted by applicable law, Apple will have no other warranty obligation whatsoever with respect to the Services, and any other claims, losses, liabilities, damages, costs or expenses attributed to any failure to conform to any warranty will be our sole responsibility.</p>

        <p>Some states do not allow exclusion of implied warranties or limitation of liability for incidental or consequential damages, so the above limitations or exclusions may not apply to you. IN SUCH STATES, OUR LIABILITY SHALL BE LIMITED TO THE GREATEST EXTENT PERMITTED BY LAW.</p>

        <h1>General</h1>
        <p>These Terms of Use, and any rights and licenses granted hereunder, may not be transferred or assigned by you, but may be assigned by us without restriction.</p>
        <p>You must represent and warrant that (i) you are not located in a country that is subject to U.S. Government embargo, or that has been designated by the U.S. Government as a “terrorist supporting” country; and (ii) you are not listed on any U.S. Government list of prohibited or restricted parties.</p>
        <p>These Terms of Use are governed by the internal substantive laws of the State of New York, without respect to its conflict of law provisions. You expressly agree to submit to the exclusive personal jurisdiction of the state and federal courts sitting in New York City in the State of New York. If any provision of these Terms of Use is found to be invalid by any court having competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of the Terms of Use, which shall remain in full force and effect. Failure by us to act on or enforce any provision of the Terms of Use shall not be construed as a waiver of that provision or any other provision in these Terms of Use. No waiver shall be effective against us unless made in writing, and no such waiver shall be construed as a waiver in any other or subsequent instance. Except as expressly agreed by us and you, these Terms of Use constitute the entire agreement between you and us with respect to the subject matter, and supersede all previous or contemporaneous agreements, whether written or oral, between the parties with respect to the subject matter. The section headings are provided merely for convenience and shall not be given any legal import.</p>
      </FinePrint>
    </TermsText>
  </Layout>
)

export default Terms;