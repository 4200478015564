import styled from 'styled-components'
import { toRem } from '../utils/styleUtils'

export const AboutText = styled.article`
    width: ${toRem(646)};
    max-width: 100%;
    margin: auto;
    font-family: Suisse-Light;
    font-size: ${toRem(24)};
    line-height: 1.375;
    text-align: center;
    color: white;
    p {
        margin: ${toRem(32)} 0;
    }
`
export const FinePrint = styled.article`
    max-width: 90%;
    font-family: Suisse-Light;
    font-size: ${toRem(12)};
    font-weight: normal;
    line-height: 1.83;
    text-align: left;
    color: white;
    h1 {
        letter-spacing: .05rem;
        font-family: Suisse-Regular;
        text-transform: uppercase;
        margin-bottom: 0;
        

    }
    
    p {
       margin: 0;
    }
a:link {
    color: gray;
}
/* A link that has been visited */
a:visited {
    color: gray;
}
/* A link that is hovered on */
a:hover {
    color: white;
    font-family: Suisse-Regular;
  }
`